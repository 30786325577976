<template>
    <div style="padding-top:150px;">
        <div v-html="detail"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            detail: "",
            type: ''
        }
    },
    created() {
        this.type = this.$route.query.type
        this.getConfig()
    },
    mounted() {
    },
    methods: {
        async getConfig() {
            let data = await this.$api.base.getConfig()
            console.log(data);
            console.log(this.type);
            if (this.type == 1) {
                this.detail = data.list[2].svalue
            } else if (this.type == 2) {
                this.detail = data.list[3].svalue
            } else if (this.type == 3) {
                this.detail = data.list[0].svalue
            } else if (this.type == 4) {
                this.detail = data.list[1].svalue
            }


        },
    }
}
</script>
<style></style>
